import React from 'react';
import { withNamespaces } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './NetworkPage.scss';
import NotAMemberComponent from '../NotAMemberComponent';
import Api from '../../utils/api';
import Input from '../InputFiled';
import PageHeaderTitle from '../PageHeaderTitle';

import {
  fetchServers,
  onFilterChanged,
  onPingServer,
  onSortByAlias,
  onSortByLoad,
  onSortByLocation,
  onSortByUptime,
} from '../../Actions/Servers';

class NetworkPage extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  componentWillMount() {
    const { fetchServers } = this.props;

    if (typeof window !== 'undefined') {
      fetchServers({ api: this.api });
    }
  }


  render() {
    const { t, brand, servers, onPingServer, onFilterChanged, filter } = this.props;

    const Spinner = () => (
      <div className='server-status-spinner'>
        <div className='bounce1' />
        <div className='bounce2' />
        <div className='bounce3' />
      </div>
    );

    const Ping = ({ ping, pingStarted, alias }) => (
      ping || pingStarted
        ? (
          <div className='pingContainer-network'>
            {ping && (
              <span>
                {ping}
                {t('page_network:ms')}
              </span>
            )}
            {pingStarted && <Spinner />}
          </div>
        )
        : (
          <button
            type='button'
            className='button-network button-network_small'
            onClick={() => onPingServer(alias)}
            disabled={pingStarted}
          >
            {t('page_network:ping')}
          </button>
        )
    );

    const Server = ({ location, load, alias, uptime, ping, pingStarted, strengthWidth, strengthContainerWidth }) => (
      <tr className='serverList-network server-network'>
        <td>
          <span className={`flag-icon flag-icon-${location.country_code.toLocaleLowerCase()}`} title={location.title} />
          <span className='serverName-network truncate-network' title={location.title} title={location.title}>
            {location.title}
          </span>
          <div className='mobileServerName-network' title={alias}>
            {alias}
          </div>
        </td>
        <td className='noMobile-network' title={alias}>
          {alias}
        </td>
        <td className='noMobile-network'>
          <span className='status-network status-network_online' />
          <span>
            {t('page_network:online').toLowerCase()}
          </span>
        </td>
        <td>
          <div className='serverLoad-network'>
            <span>
              {`${load}%`}
            </span>
            <span className='noMobile-network'>
              <div className='text-left hide-tablet no-left-padding' style={{ width: 100 }}>
                <div className='strength' style={{ width: 100 * (load / 100) }}>
                  <div style={{ width: 100 }} />
                </div>
              </div>
            </span>
          </div>
        </td>
        <td>
          {`${uptime}%`}
        </td>
        <td className='noMobile-network'>
          <Ping alias={alias} ping={ping} pingStarted={pingStarted} />
        </td>
      </tr>
    );

    return (
      <div className='page-network'>
        <PageHeaderTitle brandName={brand.name} title={t('page_network:network')} />
        <div className='wrapper-network wrapper-network_borderBottom wrapper-network_gray page-network__blockShort wrapper-network_map_img'>
          <div className='innerWrapper-network'>
            <div className='header-network'>
              <h1 className='title-network_h1'>
                {t('page_network:title_h1')}
              </h1>
              <h2 className='title-network_h2'>
                {t('page_network:title_h2')}
              </h2>
              <div className='search-network'>
                <Input
                  placeholder={t('page_network:search_placeholder')}
                  onChange={e => onFilterChanged(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='innerWrapper-network innerWrapper-network'>
            <div className='servers-network'>
              <table className='serverList-network'>
                <thead>
                  <tr>
                    <th className='cursor-p' onClick={() => this.props.onSortByLocation()}>
                      {t('page_network:location')}
                    </th>
                    <th className='noMobile-network cursor-p' onClick={() => this.props.onSortByAlias()}>
                      {t('page_network:server')}
                    </th>
                    <th className='noMobile-network'>
                      {t('page_network:status')}
                    </th>
                    <th className='cursor-p' onClick={() => this.props.onSortByLoad()}>
                      {t('page_network:load')}
                    </th>
                    <th className='cursor-p' onClick={() => this.props.onSortByUptime()}>
                      {t('page_network:uptime')}
                    </th>
                    <th className='noMobile-network'>
                      {t('page_network:latency')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {servers
                    ? servers
                      .filter(s => (
                        filter.length === 0
                      || s.location.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                      ))
                      .map(server => (
                        <Server
                          key={server.primary_ip}
                          location={server.location}
                          alias={server.alias}
                          load={server.load}
                          uptime={server.uptime}
                          ping={server.ping}
                          pingStarted={server.pingStarted}
                        />
                      ))
                    : (
                      <tr>
                        <td colSpan={6}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <NotAMemberComponent
          brand={brand.name}
          text={t('page_network:not_a_member')}
          buttonText={t('page_network:get_anonine')}
          orderUrl={this.api.orderUrl()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  servers: state.servers.servers,
  filter: state.servers.filter,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchServers,
  onFilterChanged,
  onPingServer,
  onSortByLoad,
  onSortByLocation,
  onSortByAlias,
  onSortByUptime,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(NetworkPage));
