import axios from 'axios';

import * as types from './Types';

const log = console.log; // eslint-disable-line

export const isDebugMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const fetchServers = ({ api }) => {
  const url = api.serverStatusUrl();

  return dispatch => {
    axios.get(url, {
      crossDomain: true,
    })
      .then(res => {
        const servers = res.data;
        servers.sort((s1, s2) => s2.load - s1.load);
        dispatch({
          type: types.SERVER_LIST_UPDATED,
          payload: res.data,
        });
      })
      .catch(err => {
        log('Failed to load servers', err);
      });
  };
};

export const onFilterChanged = val => ({
  type: types.FILTER_UPDATED,
  payload: val,
});

export const onPingServer = alias => dispatch => {
  // disable the ping button
  dispatch({
    type: types.START_PING,
    payload: alias,
  });

  const pingServer = bestResult => {
    const url = `https://${alias}:8443/network-speed-tester/ping.html?t=${Date.now()}`;

    let start = null;

    axios.interceptors.request.use(config => {
      start = new Date();
      return config;
    }, error => Promise.reject(error));

    return axios.head(url, { timeout: 10000 })
      .then(() => {
        const responseTime = (new Date()) - start;
        return Math.min(responseTime, bestResult);
      })
      .catch(ex => bestResult);
  };

  const attempts = 10;
  let promise = Promise.resolve(Infinity);
  for (let ping = 0; ping < attempts; ping += 1) {
    promise = promise.then(pingServer);
  }

  promise.then(responseTime => {
    if (responseTime === Infinity) {
      dispatch({
        type: types.PING_RESET,
        payload: {
          alias,
          responseTime,
        },
      });
    } else {
      dispatch({
        type: types.PING_RESPONSE,
        payload: {
          alias,
          responseTime,
        },
      });
    }

    dispatch({
      type: types.STOP_PING,
      payload: alias,
    });
  });
};

export const onSortByLoad = () => ({
  type: types.SORT_SERVERS_BY_LOAD,
});

export const onSortByLocation = () => ({
  type: types.SORT_SERVERS_BY_LOCATION,
});

export const onSortByAlias = () => ({
  type: types.SORT_SERVERS_BY_ALIAS,
});

export const onSortByUptime = () => ({
  type: types.SORT_SERVERS_BY_UPTIME,
});
